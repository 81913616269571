import React, { useEffect, useState } from 'react';

import { ReactComponent as IconPlus } from '../../../assets/images/icon-plus.svg';
import Modal from './Modal';
import PaginatedItems from '../../Elements/PaginatedItems';

import { Customerships, FormData, FormCompany } from '../../../types/companies';
import {
  addOrEditCompanyUser,
  getCompanyUserDetails
} from '../../../actions/b2bUsers';
import { apiUrl } from '../../../config/constants';
import LoginStore from '../../../store/LoginStore';
import Loading from '../Shared/Loading';
import { Link } from 'react-router-dom';

export const initialFormState = {
  firstName: '',
  lastName: '',
  email: '',
  companies: []
};

const CompanyDashboard: React.FunctionComponent = () => {
  const [viewType, setViewType] = useState('company');
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [personsData, setPersonsData] = useState<FormData[]>([]); // Maybe useful if the data is sent to backend
  const [formData, setFormData] = useState<FormData>(initialFormState);
  const [action, setAction] = useState(''); // for determining what content to show in the modal (add, edit or delete person)
  const [personIndex, setPersonIndex] = useState(-1);
  const [companies, setCompanies] = useState<Customerships[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const handleCardOpening = (idx: number) =>
    setCurrentIndex(currentValue => (currentValue !== idx ? idx : -1));

  const handleSwitchView = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    const target = e.target as HTMLButtonElement;
    target.id === 'company-button'
      ? setViewType('company')
      : setViewType('person');
    setCurrentIndex(-1); // closes open accordions when switching views
  };

  useEffect(() => {
    !LoginStore.logged && login(); // to render nav bar
    getData();
    getPersonData();
  },[]);

  const login = async () => await LoginStore.login('company', true);

  const getData = async () => {
    const company = await getCompanyUserDetails();
    const customerships: Customerships[] = [];
    company.companies.map((item: Customerships) => {
      // const isDuplicate = customerships.find(
      //   obj =>
      //     obj.customerships[0].contracts[0].contractId ===
      //     item.customerships[0].contracts[0].contractId
      // );
      // !isDuplicate && 
      customerships.push(item);
    });
    setCompanies(customerships);
    setLoading(false);
  };

  const getPersonData = async () => {
    // to render person tab and the users in it
    if (!LoginStore.logged) {
      await login();
    }
    if (LoginStore.type === 'companyManager') {
      const res = await fetch(`${apiUrl}/business/companyUsers`);
      const data = await res.json();
      setPersonsData(data);
    }
  };

  const handleModalOpening = (): void => {
    setOpenModal(!openModal);
  };

  /**
   * In theory personsData can be undefined.
   * In the case that it is we should decide what we push into the companies if it is.
   */
  const findBusinessId = (companyName: string) => {
    const allCompanies: FormCompany[] = [];

    if (companies && companies.length > 0) {
      const id = companies.find(co => co.customerName === companyName)?.identifier as string;
      return id
    } else if (personsData && personsData.length > 0) {
      personsData.map(person => {
        person.companies.map(company => {
          !allCompanies.find(co => co.identifier === company.identifier)
            ? allCompanies.push(company)
            : null;
        });
      });
      return allCompanies.find(co => co.companyName === companyName)
        ?.identifier as string;
    } else {
      return '';
    }
  };

  // handles when a company checkbox is toggled (adds or removes it from the array)
  const checkIfCompanyIsIncluded = (company: string): FormCompany[] => {
    const companies = [...formData.companies];
    const companyIdx = companies.findIndex(
      item => item.companyName === company
    );
    companyIdx !== -1
      ? companies.splice(companyIdx, 1)
      : companies.push({
        businessId: findBusinessId(company),
        companyName: company,
      });
    return companies;
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name !== 'company') {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      setFormData({
        ...formData,
        companies: checkIfCompanyIsIncluded(e.target.value)
      });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    let persons;
    const newPerson: FormData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      companies: formData.companies
    };
    addOrEditCompanyUser(newPerson, true);
    if (personsData) {
      persons = [...personsData, newPerson];
    } else {
      persons = [newPerson];
    }

    setPersonsData(persons);
    handleModalOpening();
    setFormData(initialFormState);
  };

  // This is to prevent type errors. Several of these can theoretically be undefined.
  // We should add something that is shown if they all are.
  let itemsToLoad;
  if (viewType === 'company' && companies) {
    /* companies array usually includes duplicate values for companyUsers role
     since the same Y-tunnus is on allowed_subsidiaries and the user belongs to
     userGroup with same Y-tunnus -> Filter the duplicates
    */
    itemsToLoad = companies.filter((function() {
      const seenIdentifiers = new Set();
      return function(company) {
        if (seenIdentifiers.has(company.identifier)) {
          return false;
        } else {
          seenIdentifiers.add(company.identifier);
          return true;
        }
      };
    })());
  } else if (
    typeof personsData === 'object' &&
    personsData.length > 0 &&
    LoginStore.type === 'companyManager'
  ) {
    itemsToLoad = personsData
  }

  if (loading) {
    return <Loading message="Ladataan tietoja..." />;
  }

  return (
    <div className="control-panel container">
      <>
        <h1>Hallintapaneeli</h1>
        <div className="switch-view">
          <button
            id="company-button"
            onClick={e => handleSwitchView(e)}
            className={`switch-view-button ${
              viewType === 'company' ? 'active' : ''
            }`}
          >
            Yritykset
          </button>
          {LoginStore.type === 'companyManager' ? (
            <button
              id="person-button"
              onClick={e => handleSwitchView(e)}
              className={`switch-view-button ${
                viewType === 'person' ? 'active' : ''
              }`}
            >
              Henkilöt
            </button>
          ) : (
            ''
          )}
          <Link to="/yritys/laskutustiedot" className={`switch-view-button`}>
            Laskut
          </Link>
        </div>
      </>
      <div className="cards-container">
        <div className="actions">
          {viewType === 'person' && (
            <button
              className="action add"
              onClick={() => {
                setAction('add');
                handleModalOpening();
              }}
            >
              <IconPlus />
              <span>Lisää uusi henkilö</span>
            </button>
          )}
        </div>
        <div className="cards">
          {!loading && itemsToLoad && personsData && (
            <PaginatedItems
              items={itemsToLoad}
              itemsPerPage={5}
              viewType={viewType}
              currentIndex={currentIndex}
              handleCardOpening={handleCardOpening}
              handleModalOpening={handleModalOpening}
              setAction={setAction}
              personsData={personsData}
              setPersonIndex={setPersonIndex}
              setCurrentIndex={setCurrentIndex}
            />
          )}
        </div>
      </div>
      {openModal && personsData && (
        <Modal
          action={action}
          companies={companies}
          handleModalOpening={handleModalOpening}
          handleFormChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleFormChange(e)
          }
          handleSubmit={(e: React.FormEvent<HTMLFormElement>) =>
            handleSubmit(e)
          }
          personsData={personsData}
          setPersonsData={setPersonsData}
          personIndex={personIndex}
          formData={formData}
          setFormData={setFormData}
        />
      )}
    </div>
  );
};

export default CompanyDashboard;
