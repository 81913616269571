// @flow
import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import Home from './components/Views/Shared/Home'
import ManagerLogin from './components/Views/Manager/ManagerLogin'
import ConsumerLogin from './components/Views/Consumer/ConsumerLogin'
import Feedback from './components/Views/Shared/Feedback'
import ConsumerRegister from './components/Views/Consumer/ConsumerRegister'
import ConsumerRegisterSuccess from './components/Views/Consumer/ConsumerRegisterSuccess'
import ConsumerRegisterFailure from './components/Views/Consumer/ConsumerRegisterFailure'
import RetrieveCredentials from './components/Views/Shared/RetrieveCredentials'
import RetrieveCredentialsForm from './components/Views/Shared/RetrieveCredentialsForm'
import StartUserRegistering from './components/Views/Shared/StartUserRegistering'
import ConsumerDashboard from './components/Views/Consumer/ConsumerDashboard'
import DetailsPage from './components/Views/Shared/DetailsPage'
import SubmitWaterMeasurementForm from './components/Views/Consumer/SubmitWaterMeasurementForm'
import ManagerDashboard from './components/Views/Manager/ManagerDashboard'
import ManagerRegisterDetails from './components/Views/Manager/ManagerRegisterDetails'
import ManagerCreatePassword from './components/Views/Manager/ManagerCreatePassword'
import ConsumerRegisterDetails from './components/Views/Consumer/ConsumerRegisterDetails'
import ManagerRegister from './components/Views/Manager/ManagerRegister'
import ManagerRegisterSuccess from './components/Views/Manager/ManagerRegisterSuccess'
import SuperuserDashboard from './components/Views/Superuser/SuperuserDashboard'
import AccessForbidden from './components/Views/Shared/AccessForbidden'
import NotFound from './components/Views/Shared/NotFound'
import HouseDetailsPage from './components/Views/Manager/HouseDetailsPage'
import CompanyRegisterDetails from './components/Views/Company/CompanyRegisterDetails'
import OrderFiberForm from './components/Views/Shared/OrderFiberForm'
import ConsumerInvoices from './components/Views/Consumer/Invoices/ConsumerInvoices'
import ConsumerContracts from './components/Views/Consumer/Contracts/ConsumerContracts'
import ContactDetailsForm from './components/Views/Shared/ContactDetailsForm'
import RopoDetails from './components/Views/Consumer/Invoices/RopoDetails'
import B2BRegister from './components/Views/Shared/B2BRegister'
import CompanyDashboard from './components/Views/Company/CompanyDashboard'
import CompanyLogin from './components/Views/Company/CompanyLogin'
import CompanyInvoiceDetails from './components/Views/Company/CompanyInvoiceView'

// TODO Determine if props are needed here or not.
const Routes = () => {
  // eslint-disable-line

  return (
    <Switch>
      {/* Consumer routes */}
      <Route path="/kirjaudu" component={ConsumerLogin} />
      <Route path="/rekisteroidy" component={ConsumerRegister} exact />
      <Route path="/rekisteroidy/virhe" component={ConsumerRegisterFailure} />
      <Route
        path="/rekisteroidy/aloita"
        component={StartUserRegistering}
        exact
      />
      <Route
        path="/rekisteroidy/tiedot"
        component={ConsumerRegisterDetails}
        exact
      />
      <Route path="/rekisteroidy/pyynto" component={B2BRegister} exact />
      <Route
        path="/vesilukema/:type?"
        component={SubmitWaterMeasurementForm}
        exact
      />
      <Route path="/dashboard" component={ConsumerDashboard} />

      <Route path="/laskut/:type" component={ConsumerInvoices} />
      <Route path="/laskut" component={ConsumerInvoices} />
      <Route path="/laskutustiedot" component={RopoDetails} />
      <Route path="/yritys/laskutustiedot" component={CompanyInvoiceDetails} />
      <Route path="/sopimukset" component={ConsumerContracts} />
      <Route path="/yhteystiedot" component={ContactDetailsForm} />
      <Route path="/sahko/:itemId" component={DetailsPage} />
      <Route path="/vesi/:itemId" component={DetailsPage} />
      <Route path="/kaukolampo/:itemId" component={DetailsPage} />
      <Route
        path="/rekisteroidy/success"
        component={ConsumerRegisterSuccess}
        exact
      />
      <Route
        path="/rekisteroidy/virhe"
        component={ConsumerRegisterFailure}
        exact
      />
      {/* Manager routes */}
      <Route path="/isannointi/kirjaudu" component={ManagerLogin} />
      <Route
        path="/isannointi/rekisteroidy"
        component={ManagerRegister}
        exact
      />
      <Route
        path="/isannointi/uusisalasana/:id"
        component={ManagerCreatePassword}
      />
      <Route
        path="/isannointi/rekisteroidy/tiedot"
        component={ManagerRegisterDetails}
        exact
      />
      <Route
        path="/isannointi/rekisteroidy/success"
        component={ManagerRegisterSuccess}
        exact
      />
      <Route path="/isannointi/dashboard" component={ManagerDashboard} />
      <Route path="/taloyhtio/:type/:itemId" component={HouseDetailsPage} />
      {/* Superuser routes */}
      <Route path="/paakayttaja/dashboard" component={SuperuserDashboard} />
      {/* Company routes */}
      <Route path="/yritys/kirjaudu" component={CompanyLogin} />
      <Route path="/yritys/yleisnakyma" component={CompanyDashboard} exact />
      <Route
        path="/yritys/rekisteroidy/tiedot"
        component={CompanyRegisterDetails}
        exact
      />
      <Route path="/yritys/sahko/:itemId/:companyId" component={DetailsPage} />
      <Route path="/yritys/kaukolampo/:itemId/:companyId" component={DetailsPage} />
      {/* Common routes */}
      <Route exact path="/" component={Home} />
      <Route path="/feedback" component={Feedback} />
      <Route path="/palauta" component={RetrieveCredentials} exact />
      <Route path="/kuitu" component={OrderFiberForm} exact />
      <Route path="/tunnus/virhe" component={ConsumerRegisterFailure} exact />
      <Route path="/tunnus" component={RetrieveCredentialsForm} exact />
      {/* Pass through all server api routes */}
      <Route path="/api" />
      {/* 403 route */}
      <Route path="/forbidden" component={AccessForbidden} />
      {/* Catch all unmatched */}
      <Route component={NotFound} />
    </Switch>
  )
}

// TODO Troubleshoot and correct this.
export default withRouter(inject('LoginStore')(observer(Routes)))
