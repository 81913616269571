import React, { useEffect } from 'react'
import moment from 'moment';
import { getRopoCompanyInvoices } from '../../../actions/getRopoDetails';
import { ropoInvoice, companyParams } from '../../../types/ropo';
import InvoicesCalendar from '../../Elements/InvoicesCalendar';
import BackButton from '../../Elements/BackButton';
import { themeDetails } from '../../../theme/theme.ts'
import RopoInvoiceDetails from '../Consumer/Invoices/RopoInvoiceDetails';

const CompanyInvoiceDetails: React.FunctionComponent = () => {
  const [invoices, setInvoices] = React.useState<ropoInvoice[]>();
  const [loading, setLoading] = React.useState(true);
  const [startDate, setStartDate] = React.useState<moment.Moment | null>(moment().subtract(1, 'year').startOf('year'));
  const [endDate, setEndDate] = React.useState<moment.Moment | null>(moment().endOf('year'));
  const [companiesInfo, setCompaniesInfo] = React.useState(null);
  const [selectedCompanies, setSelectedCompanies] = React.useState<string[]>([]);
  const [onlineMaxSaldoLimit, setOnlineMaxSaldoLimit] = React.useState<number>(0);
  const [invoiceArray, setInvoiceArray] = React.useState<JSX.Element[]>();
  const [selectedMeteringPoints, setSelectedMeteringPoints] = React.useState<string[]>([]);
  const [uniqueMeteringPoints, setUniqueMeteringpoints] = React.useState<string[]>([]);

  useEffect(() => {
    async function fetchCompanyInvoices(): Promise<void> {
      setLoading(true);
      const companyInvoiceData = await getRopoCompanyInvoices()
      if (companyInvoiceData && companyInvoiceData.invoices.length > 0) {
        const { companyParams, invoices, companyInfo } = companyInvoiceData;
        const onlineMaxSaldoLimitParam = companyParams.find((param: companyParams) => param.param === "OnlineMaxSaldoLimit");
        const onlineMaxSaldoLimitValue = onlineMaxSaldoLimitParam ? parseFloat(onlineMaxSaldoLimitParam.value) : 0;

        const sortedInvoices = [...invoices].sort((a, b) =>
          moment(b.duedate).diff(moment(a.duedate))
        );

        const allMeteringPoints = sortedInvoices.flatMap(invoice =>
          invoice.calculation_periods.map(p => p.object_key)
        );
        const uniquePoints = Array.from(new Set(allMeteringPoints));

        setOnlineMaxSaldoLimit(onlineMaxSaldoLimitValue);
        setInvoices(sortedInvoices);
        setCompaniesInfo(companyInfo);
        setUniqueMeteringpoints(uniquePoints);
        setLoading(false);
      }
    }
    fetchCompanyInvoices();
  }, []);

  useEffect(() => {
    if (invoices) {
      const filteredInvoices = invoices.filter((invoice) => {
        const matchesCompany =
          selectedCompanies.length === 0 ||
          selectedCompanies.some((customerIdArray) =>
            customerIdArray.includes(invoice.customer_number)
          );

        const dueDate = moment(invoice.duedate);
        const matchesDateRange =
          dueDate.isSameOrAfter(startDate, "day") &&
          dueDate.isSameOrBefore(endDate, "day");

        const matchesMeteringPoint =
          selectedMeteringPoints.length === 0 ||
          invoice.calculation_periods.some(p =>
            selectedMeteringPoints.includes(p.object_key)
          );

        return matchesCompany && matchesDateRange && matchesMeteringPoint;
      });

      const updatedInvoiceArray: JSX.Element[] = filteredInvoices.map((invoice) => (
        <RopoInvoiceDetails
          allInvoices={invoices}
          setInvoices={setInvoices}
          invoice={invoice}
          key={invoice.invoice_number}
          onlineMaxSaldoLimit={onlineMaxSaldoLimit}
        />
      ));

      setInvoiceArray(updatedInvoiceArray);
      setLoading(false);
    }
  }, [invoices, selectedCompanies, selectedMeteringPoints, startDate, endDate]);

  /** Handler for Company checkbox */
  const handleCheckboxChange = (customerIdentifier: string) => {
    const updated = selectedCompanies.includes(customerIdentifier)
      ? selectedCompanies.filter(id => id !== customerIdentifier)
      : [...selectedCompanies, customerIdentifier];
    setSelectedCompanies(updated);
  };
  /** Handler for Metering Point checkbox */
  const handleMeteringPointChange = (point: string) => {
    const updated = selectedMeteringPoints.includes(point)
      ? selectedMeteringPoints.filter(p => p !== point)
      : [...selectedMeteringPoints, point];
    setSelectedMeteringPoints(updated);
  };

  /** 
   * Invoice table - same as in RopoDetails component
   * TODO: Could be created as a separate component to reuse code more
   */
  let invoiceTable: JSX.Element = <></>;
  invoiceTable = (
    <div className="ropo-table-responsive">
      <table>
        <tbody>
          <tr>
            <td>Eräpäivä</td>
            <td>Laskun numero</td>
            <td>Laskutyyppi</td>
            <td>Laskun summa</td>
            <td>Maksettava</td>
            <td>Laskun tila</td>
            <td>Laskun pdf</td>
            <td>Siirrä eräpäivää</td>
          </tr>
          {invoiceArray}
        </tbody>
      </table>
    </div>
  );

  return (
    <div>
      <div className="wrapper contracts">
        {/** Back Button takes you back to the Dashboard */}
        <div className="btn-row">
          <BackButton to="/yritys/yleisnakyma"/>
          <h1>Laskutiedot</h1>
        </div>
        <div className='filter-wrapper'>
          {/** Calendar view to select start and end dates */}
          <InvoicesCalendar
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
          />
          <div className='checkbox-wrapper'>
            {/** Check box list for selecting invoices based on Company */}
            <div className="company-filter">
              <strong>Valitse yhtiöt:</strong>
              <div>
                {companiesInfo && companiesInfo.map((company) => (
                  <label key={company.identifier} style={{ display: 'block', marginBottom: '4px' }}>
                    <input
                      type="checkbox"
                      value={company.customerIdentifier}
                      checked={selectedCompanies.includes(company.customerIdentifier)}
                      onChange={() => handleCheckboxChange(company.customerIdentifier)}
                    />
                    {` ${company.customerName}`}
                  </label>
                ))}
              </div>
            </div>
            {/** Check box list for selecting invoices based on Meteringpoint */}
            <div className="metering-point-filter">
              <strong>Valitse mittauspisteet:</strong>
              <div>
                {uniqueMeteringPoints.map((point) => (
                  <label key={point} style={{ display: 'block', marginBottom: '4px' }}>
                    <input
                      type="checkbox"
                      checked={selectedMeteringPoints.includes(point)}
                      onChange={() => handleMeteringPointChange(point)}
                    />
                    {` ${point}`}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
        {loading && <p>Haetaan laskutietoja</p>}
        {!loading && !invoices && <p>Laskutietojen haku epäonnistui</p>}
        {!loading && invoices && invoiceTable}
      </div>

      {themeDetails.theme === 'tke' && (
        <div className="tke-invoice-support">
          <p>
            Laskun maksamiseen ja myöhästyneisiin maksuihin liittyvissä asioissa
            sinua palvelee kumppanimme Ropo:{" "}
            <a href="https://ropo.fi" target="_blank" rel="noopener noreferrer">
              ropo.fi
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

export default CompanyInvoiceDetails;
