import { AxiosResponse } from 'axios'

// Helper.
import API from './Api'
// Types.
import { IinvoicesResponse, ropoInvoice } from '../types/ropo'

export const getRopoInvoices = async (): Promise<IinvoicesResponse | null> => {
  try {
    const invoices: AxiosResponse<IinvoicesResponse> = await API({
      method: 'get',
      url: `/ropo/invoices`,
    })

    return invoices.data
  } catch (err) {
    return null
  }
}

export const getRopoCompanyInvoices = async (): Promise<IinvoicesResponse | null> => {
  try {
    const invoices: AxiosResponse<IinvoicesResponse> = await API({
      method: 'get',
      url: `/ropo/companyInvoices`,
    })

    return invoices.data
  } catch (err) {
    return null
  }
}

export const getRopoPdf = async (invoiceId: string): Promise<string> => {
  const billDetails: AxiosResponse<string> = await API({
    method: 'get',
    url: `/ropo/pdf/${invoiceId}`,
  })

  return billDetails.data
}

export const postDueDateChange = async (
  invoiceId: string,
  newDueDate: string
): Promise<ropoInvoice> => {
  const formattedDate = newDueDate
  const endpoint = `/ropo/postponeInvoiceDuedate/${invoiceId}/${formattedDate}`
  const response = await API({
    method: 'patch',
    url: endpoint,
  })
  return response.data
}
